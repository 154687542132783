import axios from "axios";

import {
  ADD_LOCATION,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_ERROR,
  ADD_LOCATION_RESET,
} from "./types";

export const addLocation = ({
  globalName,
  locationAlias,
  latitude,
  longitude,
}) => {
  return (dispatch) => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/locations`,
        {
          Global_location_name: globalName,
          location_alias: locationAlias,
          latitude,
          longitude,
        },
        { headers }
      )
      .then(({ data }) => {
        const payload = {
          data: data,
        };
        dispatch(success(payload));
      })
      .catch((err) => {
        console.log("ERROR! [addLocationAction] " + err.message);
        const payload = {
          errorMessage: err.message,
        };
        dispatch(error(payload));
      });
  };
};

const loading = () => ({
  type: ADD_LOCATION,
});

const success = (data) => ({
  type: ADD_LOCATION_SUCCESS,
  payload: {
    ...data,
  },
});

const error = (data) => ({
  type: ADD_LOCATION_ERROR,
  payload: {
    ...data,
  },
});

export const reset = () => {
  return (dispatch) => {
    dispatch({
      type: ADD_LOCATION_RESET,
    });
  };
};
