import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Typography,
  Paper,
  Toolbar,
  Button,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";

import { tableIcons } from "../../helpers/tableIcons";
import DashboardLayout from "../../layout/Dashboard";
import breadcrumbGenerator from "../../helpers/breadcrumbGenerator";
import { loadSetupLocations } from "../../actions/loadSetupLocations";
import { reset as resetAdd } from "../../actions/addLocation";
import { reset as resetUpdate } from "../../actions/updateLocation";
import AddLocation from "./AddLocation";

function Locations(props) {
  const { title } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const locations = useSelector((state) => state.setupLocations.data);

  const [open, setOpen] = useState(false);
  const [updateData, setUpdateData] = useState({});

  const [buttonMode, setButtonMode] = useState("add");

  const handleOpen = () => {
    setButtonMode("add");
    setOpen(true);
  };

  const handleEdit = (id) => {
    const locationItem = locations.find((o) => o.id === id);
    setButtonMode("update");
    setUpdateData({
      globalName: locationItem?.global_location_name,
      locationAlias: locationItem?.location_alias,
      latitude: locationItem?.latitude,
      longitude: locationItem?.longitude,
      locationId: id,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      dispatch(loadSetupLocations());
      dispatch(resetAdd());
      dispatch(resetUpdate());
    }
  }, [dispatch, open]);

  return (
    <DashboardLayout
      title={breadcrumbGenerator([{ title: title }])}
      documentTitle="Locations"
    >
      <div
        style={{
          flexGrow: 1,
          height: "100%",
          padding: "16px",
          overflowY: "scroll",
        }}
      >
        <AddLocation
          open={open}
          handleClose={handleClose}
          updateData={updateData}
          mode={buttonMode}
        />

        <Toolbar>
          <Typography variant="h6" id="tableTitle">
            Locations
          </Typography>
          <div style={{ flex: "1 1 100%" }} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            style={{ whiteSpace: "nowrap" }}
          >
            Add Location
          </Button>
        </Toolbar>

        <Paper className={classes.paper}>
          <MaterialTable
            icons={tableIcons}
            options={{
              headerStyle: { backgroundColor: "black", color: "white" },
              rowStyle: (rowData) => ({
                backgroundColor: rowData.tableData.id % 2 ? "#EEE" : "#FFF",
              }),
              paging: false,
              maxBodyHeight: 450,
              draggable: false,
            }}
            title=""
            columns={[
              // {
              //   title: "ID",
              //   field: "id",
              // },
              {
                title: "Global Name",
                field: "global_location_name",
              },
              {
                title: "Alias",
                field: "location_alias",
              },
              {
                title: "Latitude",
                field: "latitude",
              },
              {
                title: "Longitude",
                field: "longitude",
              },
              {
                title: "State",
                field: "state",
              },
              {
                title: "Modify",
                render: (data, key) => {
                  return (
                    <>
                      <IconButton
                        size="small"
                        onClick={() => handleEdit(data?.id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </>
                  );
                },
              },
            ]}
            data={locations}
          />
        </Paper>
      </div>
    </DashboardLayout>
  );
}

const useStyles = makeStyles((theme) => ({
  form: {},
}));

export default Locations;
