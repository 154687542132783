import axios from "axios";

import {
  ADD_USER_LOCATIONS,
  ADD_USER_LOCATIONS_SUCCESS,
  ADD_USER_LOCATIONS_ERROR,
  ADD_USER_LOCATIONS_RESET,
} from "./types";

export const addUserLocations = (id, unitList) => {
  return (dispatch) => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/locations/units/user/${id}`,
        {
          units: unitList,
        },
        { headers }
      )
      .then(({ data }) => {
        const payload = {
          data: data,
        };
        dispatch(success(payload));
      })
      .catch((err) => {
        console.log("ERROR! [addUserLocationsAction] " + err.message);
        const payload = {
          errorMessage: err.message,
        };
        dispatch(error(payload));
      });
  };
};

const loading = () => ({
  type: ADD_USER_LOCATIONS,
});

const success = (data) => ({
  type: ADD_USER_LOCATIONS_SUCCESS,
  payload: {
    ...data,
  },
});

const error = (data) => ({
  type: ADD_USER_LOCATIONS_ERROR,
  payload: {
    ...data,
  },
});

export const reset = () => {
  return (dispatch) => {
    dispatch({
      type: ADD_USER_LOCATIONS_RESET,
    });
  };
};
