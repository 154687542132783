import {
  ADD_USER_LOCATIONS,
  ADD_USER_LOCATIONS_SUCCESS,
  ADD_USER_LOCATIONS_ERROR,
  ADD_USER_LOCATIONS_RESET,
} from "../actions/types";

export const initialState = {
  loading: true,
  data: null,
};

function addUserLocationsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_USER_LOCATIONS:
      return {
        ...state,
        data: null,
        loading: true,
        error: false,
      };
    case ADD_USER_LOCATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };
    case ADD_USER_LOCATIONS_ERROR:
      return {
        ...state,
        data: null,
        loading: true,
        error: true,
        errorMessage: action.payload.errorMessage,
      };
    case ADD_USER_LOCATIONS_RESET:
      return {
        ...state,
        data: null,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
}

export default addUserLocationsReducer;
