import axios from "axios";

import {
  UPDATE_SENSOR,
  UPDATE_SENSOR_SUCCESS,
  UPDATE_SENSOR_ERROR,
  UPDATE_SENSOR_RESET,
} from "./types";

export const updateSensor = (
  id,
  {
    // globalName, // FIXME: Ask Ashpak
    alias,
    subTypeId,
    upperLimitEnable,
    upperLimit,
    lowerLimitEnable,
    lowerLimit,
    metricUnit,
    aggregation,
    sendAlerts,
    isActive,
  }
) => {
  return (dispatch) => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/sensor/${id}`,
        {
          SensorAlias: alias,
          // SensorSubType_id: subTypeId, // FIXME: Ask Ashpak
          upper_limit_is_applicable: upperLimitEnable,
          UpperLimit: upperLimit,
          lower_limit_is_applicable: lowerLimitEnable,
          LowerLimit: lowerLimit,
          // MetricUnit: metricUnit, // FIXME: Ask Ashpak
          Aggregation: aggregation,
          SendAlert: sendAlerts,
          is_active: isActive,
        },
        { headers }
      )
      .then(({ data }) => {
        const payload = {
          data: data,
        };
        dispatch(success(payload));
      })
      .catch((err) => {
        console.log("ERROR! [updateSensorAction] " + err.message);
        const payload = {
          errorMessage: err.message,
        };
        dispatch(error(payload));
      });
  };
};

const loading = () => ({
  type: UPDATE_SENSOR,
});

const success = (data) => ({
  type: UPDATE_SENSOR_SUCCESS,
  payload: {
    ...data,
  },
});

const error = (data) => ({
  type: UPDATE_SENSOR_ERROR,
  payload: {
    ...data,
  },
});

export const reset = () => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SENSOR_RESET,
    });
  };
};
