import {
    LOAD_USERS,
    LOAD_USERS_SUCCESS,
    LOAD_USERS_ERROR,
  } from "../actions/types";
  
  export const initialState = {
    loading: true,
    data: [],
  };
  
  function usersReducer(state = initialState, action) {
    switch (action.type) {
      case LOAD_USERS:
        return {
          ...state,
          data: [],
          loading: true,
          error: false,
        };
      case LOAD_USERS_SUCCESS:
        return {
          ...state,
          data: action.payload.data,
          loading: false,
          error: false,
        };
      case LOAD_USERS_ERROR:
        return {
          ...state,
          data: [],
          loading: true,
          error: true,
          errorMessage: action.payload.errorMessage,
        };
      default:
        return state;
    }
  }
  
  export default usersReducer;
  