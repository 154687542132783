import {
  ADD_UNIT,
  ADD_UNIT_SUCCESS,
  ADD_UNIT_ERROR,
  ADD_UNIT_RESET,
} from "../actions/types";

export const initialState = {
  loading: true,
  data: null,
};

function addUnitReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_UNIT:
      return {
        ...state,
        data: null,
        loading: true,
        error: false,
      };
    case ADD_UNIT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };
    case ADD_UNIT_ERROR:
      return {
        ...state,
        data: null,
        loading: true,
        error: true,
        errorMessage: action.payload.errorMessage,
      };
    case ADD_UNIT_RESET:
      return {
        ...state,
        data: null,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
}

export default addUnitReducer;
