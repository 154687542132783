import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Typography,
  Paper,
  Toolbar,
  Button,
  IconButton,
} from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import MaterialTable from "material-table";

import { tableIcons } from "../../helpers/tableIcons";
import DashboardLayout from "../../layout/Dashboard";
import breadcrumbGenerator from "../../helpers/breadcrumbGenerator";
import { loadSensors } from "../../actions/loadSensors";
import AddSensor from "./AddSensor";
import { deleteSensor } from "../../actions/deleteSensor";
import { reset as resetAdd } from "../../actions/addSensor";
import { reset as resetUpdate } from "../../actions/updateSensor";

function Sensor(props) {
  const { title } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const sensors = useSelector((state) => state.sensors.data);

  const [open, setOpen] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [buttonMode, setButtonMode] = useState("add");

  useEffect(() => {
    if (!open) {
      dispatch(loadSensors());
      dispatch(resetAdd());
      dispatch(resetUpdate());
    }
  }, [dispatch, open]);

  const handleOpen = () => {
    setButtonMode("add");
    setOpen(true);
  };
  const handleEdit = (id) => {
    const sensorItem = sensors.find((i) => i.sensor_id === id);
    setButtonMode("update");
    setUpdateData({
      sensorId: sensorItem?.sensor_id,
      // location:sensors?., FIXME: Ask ashpak
      // sensorType: sensors[id]?.sensor_id, FIXME: Ask ashpak - sensorType id is required
      unit: sensorItem?.unit_id,
      upperLimitChecked: sensorItem?.upper_limit_is_applicable,
      lowerLimitChecked: sensorItem?.lower_limit_is_applicable,
      upperLimit: sensorItem?.upper_limit,
      lowerLimit: sensorItem?.lower_limit,
      metricUnit: sensorItem?.metric_unit,
      aggregation: sensorItem?.aggregation_time_period,
      alias: sensorItem?.sensor_alias,
      isActive: sensorItem?.is_active,
      sendAlerts: sensorItem?.is_send_alert,
      name: sensorItem?.global_sensor_name,
    });
    setOpen(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      dispatch(deleteSensor(id));
      setTimeout(() => {
        dispatch(loadSensors());
      }, 1000);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DashboardLayout
      title={breadcrumbGenerator([{ title: title }])}
      documentTitle="Sensors"
    >
      <div
        style={{
          flexGrow: 1,
          height: "100%",
          padding: "16px",
          overflowY: "scroll",
        }}
      >
        {/* <Table className={classes.table}> */}

        {open && (
          <AddSensor
            handleClose={handleClose}
            open={open}
            updateData={updateData}
            mode={buttonMode}
          />
        )}

        <Toolbar>
          <Typography variant="h6" id="tableTitle">
            Sensors
          </Typography>
          <div style={{ flex: "1 1 100%" }} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            style={{ whiteSpace: "nowrap" }}
          >
            Add Sensor
          </Button>
        </Toolbar>

        <Paper className={classes.paper}>
          <MaterialTable
            icons={tableIcons}
            options={{
              headerStyle: { backgroundColor: "black", color: "white" },
              rowStyle: (rowData) => ({
                backgroundColor:
                  // eslint-disable-next-line eqeqeq
                  rowData.is_active == false
                    ? "#BBB"
                    : rowData.tableData.id % 2
                    ? "#EEE"
                    : "#FFF",
              }),
              paging: false,
              maxBodyHeight: 450,
              draggable: false,
            }}
            title=""
            columns={[
              // {
              //   title: "Sensor ID",
              //   field: "sensor_id",
              // },
              {
                title: "Global Sensor Name",
                field: "global_sensor_name",
              },
              {
                title: "Sensor Alias",
                field: "sensor_alias",
              },
              {
                title: "Sensor Type",
                field: "sensor_type",
              },
              {
                title: "Upper Limit",
                field: "upper_limit",
                render: (data) => {
                  return data.upper_limit_is_applicable
                    ? data.upper_limit
                    : "N/A";
                },
              },
              {
                title: "Lower Limit",
                field: "lower_limit",
                render: (data) => {
                  return data.lower_limit_is_applicable
                    ? data.lower_limit
                    : "N/A";
                },
              },
              {
                title: "Metric Unit",
                field: "metric_unit",
              },
              {
                title: "Aggregation Time Period",
                field: "aggregation_time_period",
              },
              // {
              //   title: "Unit ID",
              //   field: "unit_id",
              // },
              {
                title: "Unit Alias",
                field: "unit_alias",
              },
              {
                title: "Global Unit Name",
                field: "global_unit_name",
              },
              {
                title: "Active Status",
                field: "is_active",
                render: (data) => {
                  // eslint-disable-next-line eqeqeq
                  return data.is_active == true ? "Active" : "Inactive";
                },
              },
              {
                title: "Alert Status",
                field: "is_send_alert",
                render: (data) => {
                  // eslint-disable-next-line eqeqeq
                  return data.is_send_alert == true ? "Enabled" : "Disabled";
                },
              },
              // {
              //   title: "Sensor Status",
              //   field: "is_disabled",
              //   render: (data) => {
              //     // eslint-disable-next-line eqeqeq
              //     return data.is_disabled == true ? "Disabled" : "Enabled";
              //   },
              // },
              {
                title: "Modify",
                render: (data) => {
                  return (
                    <>
                      <IconButton
                        size="small"
                        onClick={() => handleEdit(data?.sensor_id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handleDelete(data?.sensor_id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  );
                },
              },
            ]}
            data={sensors}
          />
        </Paper>
      </div>
    </DashboardLayout>
  );
}

const useStyles = makeStyles((theme) => ({
  form: {},
}));

export default Sensor;
