import axios from "axios";

import {
  DELETE_UNIT,
  DELETE_UNIT_SUCCESS,
  DELETE_UNIT_ERROR,
  DELETE_UNIT_RESET,
} from "./types";

export const deleteUnit = (id) => {
  return (dispatch) => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/locations/unit/${id}`, {
        headers,
      })
      .then(({ data }) => {
        // Alert
        alert(data.result);

        const payload = {
          data: data,
        };
        dispatch(success(payload));
      })
      .catch((err) => {
        console.log("ERROR! [deleteUnitAction] " + err.message);
        const payload = {
          errorMessage: err.message,
        };
        dispatch(error(payload));
      });
  };
};

const loading = () => ({
  type: DELETE_UNIT,
});

const success = (data) => ({
  type: DELETE_UNIT_SUCCESS,
  payload: {
    ...data,
  },
});

const error = (data) => ({
  type: DELETE_UNIT_ERROR,
  payload: {
    ...data,
  },
});

export const reset = () => {
  return (dispatch) => {
    dispatch({
      type: DELETE_UNIT_RESET,
    });
  };
};
