import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControl,
  InputLabel,
  Chip,
  Select,
  FormControlLabel,
  DialogContent,
  Snackbar,
  Typography,
  MenuItem,
  Input,
  ListItemText,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";

import { addUserLocations } from "../../actions/addUserLocations";
import { loadSetupLocations } from "../../actions/loadSetupLocations";
import { loadUnits } from "../../actions/loadUnits";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddUserLocation = ({ handleClose, open, userId, list }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.setupLocations.data);
  const units = useSelector((state) => state.units.data);
  const addUserLocationsResult = useSelector((state) => state.addUserLocations);

  const unitList = units.map((u) => u.unit_id);

  const [location, setLocation] = useState(null);
  const [unit, setUnit] = useState([]);

  const handleAddUnit = (event) => {
    event.preventDefault();
    if (window.confirm("Are you sure to add?")) {
      dispatch(addUserLocations(userId, unit));
      setTimeout(handleClose, 1000);
    }
  };

  useEffect(() => {
    setUnit(list?.map((i) => i.unit_id));
  }, [list]);

  useEffect(() => {
    dispatch(loadSetupLocations());
  }, [dispatch]);

  useEffect(() => {
    if (location) {
      dispatch(loadUnits(locations[location].id));
    }
  }, [dispatch, location, locations]);

  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState();

  useEffect(() => {
    if (addUserLocationsResult.data) {
      setOpenSnack(true);
      setMessage(addUserLocationsResult.data.result);
    }

    return () => {
      setOpenSnack(false);
      setMessage();
    };
  }, [addUserLocationsResult, message]);

  useEffect(() => {
    console.log(unit);
  }, [unit]);

  return (
    <>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={() => setOpenSnack(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert severity="info">
          <Typography variant="h5" style={{ color: "#fff" }}>
            {message}
          </Typography>
        </Alert>
      </Snackbar>
      <Dialog onClose={handleClose} open={open} fullWidth>
        <DialogTitle>Edit User Unit Mapping</DialogTitle>
        <form
          className={classes.form}
          autoComplete="off"
          onSubmit={handleAddUnit}
        >
          <DialogContent>
            <div>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                required
              >
                <InputLabel id="location">Location</InputLabel>
                <Select
                  native
                  variant="outlined"
                  value={location}
                  onChange={(event) => {
                    setLocation(event.target.value);
                  }}
                  labelId="location"
                  label="Location"
                >
                  <option aria-label="None" value="" />
                  {locations.map((item, key) => (
                    <option value={key}>{item.global_location_name}</option>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* <div>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                required
              >
                <InputLabel id="unit">Unit</InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={unit}
                  onChange={(event) => {
                    setUnit(event.target.value);
                  }}
                  input={<Input />}
                  renderValue={(selected) =>
                    units //FIXME: This logic is wrong
                      .find((i) => i.unit_id === selected)
                      ?.global_unit_name.join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {unitList.map((id) => {
                    const unitName = units.find(
                      (i) => i.unit_id === id
                    )?.global_unit_name;

                    return (
                      <MenuItem key={id} value={id}>
                        <Checkbox checked={unit?.indexOf(id) > -1} />
                        <ListItemText primary={unitName} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div> */}
            <div>
              <FormControl
                // variant="outlined"
                className={classes.unitFormControl}
              >
                <InputLabel id="demo-mutiple-chip-label">Unit</InputLabel>
                <Select
                  // variant="outlined"
                  labelId="demo-mutiple-chip-label"
                  label="Unit"
                  id="demo-mutiple-chip"
                  multiple
                  value={unit}
                  onChange={(event) => {
                    setUnit(event.target.value);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => {
                    const subSelected = selected.filter(
                      (s) => units.findIndex((u) => u.unit_id === s) > -1
                    );
                    if (subSelected.length === 0) {
                      return <em>Select Units</em>;
                    }
                    return (
                      <div className={classes.chips}>
                        {subSelected.map((value) => (
                          <Chip
                            key={value}
                            label={
                              units.find((i) => i.unit_id === value)
                                ?.global_unit_name
                            }
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    );
                  }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled value="">
                    <em>Units</em>
                  </MenuItem>
                  {unitList.map((item) => {
                    const unitName = units.find(
                      (i) => i.unit_id === item
                    )?.global_unit_name;

                    return (
                      <MenuItem
                        key={item}
                        value={item}
                        // style={getStyles(name, personName, theme)}
                      >
                        {unitName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {/* <div>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                required
              >
                <InputLabel htmlFor="unit">Unit</InputLabel>
                <Select
                  native
                  variant="outlined"
                  value={unit}
                  onChange={(event) => {
                    setUnit(event.target.value);
                  }}
                  inputProps={{
                    name: "unit",
                    id: "unit",
                  }}
                >
                  <option aria-label="None" value="" />
                  {units.map(
                    (item, key) =>
                      // eslint-disable-next-line eqeqeq
                      item.is_active == true && (
                        <option value={key}>{item["global_unit_name"]}</option>
                      )
                  )}
                </Select>
              </FormControl>
            </div> */}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" type="submit">
              Update
            </Button>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    "& > *": {
      margin: theme.spacing(1),
      // width: "100%",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  unitFormControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 400,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 1,
  },
}));

export default AddUserLocation;
