import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Typography,
  Paper,
  Toolbar,
  Button,
  IconButton,
  InputLabel,
  FormControl,
  Select,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";

import { tableIcons } from "../../helpers/tableIcons";
import DashboardLayout from "../../layout/Dashboard";
import breadcrumbGenerator from "../../helpers/breadcrumbGenerator";
import { loadSetupLocations } from "../../actions/loadSetupLocations";
import { loadUnits } from "../../actions/loadUnits";
import { deleteUnit } from "../../actions/deleteUnit";
import { reset as resetAdd } from "../../actions/addUnit";
import { reset as resetUpdate } from "../../actions/updateUnit";
import AddUnit from "./AddUnit";

function Units(props) {
  const { title } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const locations = useSelector((state) => state.setupLocations.data);
  const units = useSelector((state) => state.units.data);

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  useEffect(() => {
    inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
  }, [locations]);

  const [open, setOpen] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [location, setLocation] = useState();

  const [buttonMode, setButtonMode] = useState("add");

  const handleOpen = () => {
    setButtonMode("add");
    setOpen(true);
  };

  const handleEdit = (id) => {
    const unitItem = units.find((o) => o.unit_id === id);
    setButtonMode("update");
    setUpdateData({
      globalName: unitItem?.global_unit_name,
      unitAlias: unitItem?.unit_name,
      unitId: id,
      isActive: unitItem?.is_active,
    });
    setOpen(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      dispatch(deleteUnit(id));
      setTimeout(() => {
        dispatch(loadUnits(location));
      }, 1000);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(loadSetupLocations());
  }, [dispatch]);

  useEffect(() => {
    if (!open) {
      dispatch(loadUnits(location));
      dispatch(resetAdd());
      dispatch(resetUpdate());
    }
  }, [dispatch, location, open]);

  return (
    <DashboardLayout
      title={breadcrumbGenerator([{ title: title }])}
      documentTitle="Locations"
    >
      <div
        style={{
          flexGrow: 1,
          height: "100%",
          padding: "16px",
          overflowY: "scroll",
        }}
      >
        <AddUnit
          open={open}
          handleClose={handleClose}
          updateData={updateData}
          mode={buttonMode}
          locationId={Number(location)}
        />

        <Toolbar>
          <FormControl className={classes.formControl}>
            <InputLabel ref={inputLabel} htmlFor="warehouse-label-placeholder">
              Warehouse
            </InputLabel>

            <Select
              native
              defaultValue={props.selection}
              className={classes.selectEmpty}
              value={props.selection}
              onChange={(e) => setLocation(e.target.value)}
              labelWidth={labelWidth}
              inputProps={{
                name: "location",
                id: "warehouse-label-placeholder",
              }}
              style={{ minWidth: 200 }}
            >
              <option value={0}>Select</option>
              {locations.map((item, key) => {
                return (
                  <option key={key} value={item.id}>
                    {item.global_location_name}
                    {item.location_alias && `(${item.location_alias})`}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <div style={{ flex: "1 1 100%" }} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            disabled={!location}
            style={{ whiteSpace: "nowrap" }}
          >
            Add Unit
          </Button>
        </Toolbar>

        <Paper className={classes.paper}>
          <MaterialTable
            icons={tableIcons}
            options={{
              headerStyle: { backgroundColor: "black", color: "white" },
              rowStyle: (rowData) => ({
                backgroundColor:
                  // eslint-disable-next-line eqeqeq
                  rowData.is_active == false
                    ? "#BBB"
                    : rowData.tableData.id % 2
                    ? "#EEE"
                    : "#FFF",
              }),
              paging: false,
              maxBodyHeight: 450,
              draggable: false,
            }}
            title=""
            columns={[
              // {
              //   title: "ID",
              //   field: "unit_id",
              // },
              {
                title: "Global Name",
                field: "global_unit_name",
              },
              {
                title: "Unit Alias",
                field: "unit_name",
              },
              {
                title: "Active Status",
                field: "is_active",
                render: (data) => {
                  // eslint-disable-next-line eqeqeq
                  return data.is_active == true ? "Active" : "Inactive";
                },
              },
              {
                title: "Warehouse Level Unit",
                field: "is_warehouse_level_unit",
                render: (data) => {
                  // eslint-disable-next-line eqeqeq
                  return data.is_warehouse_level_unit == true ? "YES" : "NO";
                },
              },
              {
                title: "Modify",
                render: (data, key) => {
                  return (
                    <>
                      <IconButton
                        size="small"
                        onClick={() => handleEdit(data?.unit_id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleDelete(data?.unit_id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  );
                },
              },
            ]}
            data={units}
          />
        </Paper>
      </div>
    </DashboardLayout>
  );
}

const useStyles = makeStyles((theme) => ({
  form: {},
  selectEmpty: {
    width: "100px",
    marginTop: theme.spacing(2),
  },
}));

export default Units;
