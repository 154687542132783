import axios from "axios";

import {
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  DELETE_USER_RESET,
} from "./types";

export const deleteUser = (id) => {
  return (dispatch) => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/user/${id}`, { headers })
      .then(({ data }) => {
        // Alert
        alert(data.result);

        const payload = {
          data: data,
        };
        dispatch(success(payload));
      })
      .catch((err) => {
        console.log("ERROR! [deleteUserAction] " + err.message);
        const payload = {
          errorMessage: err.message,
        };
        dispatch(error(payload));
      });
  };
};

const loading = () => ({
  type: DELETE_USER,
});

const success = (data) => ({
  type: DELETE_USER_SUCCESS,
  payload: {
    ...data,
  },
});

const error = (data) => ({
  type: DELETE_USER_ERROR,
  payload: {
    ...data,
  },
});

export const reset = () => {
  return (dispatch) => {
    dispatch({
      type: DELETE_USER_RESET,
    });
  };
};
