import axios from "axios";

import {
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER_RESET,
} from "./types";

export const updateUser = (
  id,
  { userName, firstName, lastName, password, receiveAlerts, isActive, roleId }
) => {
  return (dispatch) => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/user/${id}`,
        {
          User_Name: userName,
          First_Name: firstName,
          Last_Name: lastName,
          // password: password,
          is_receive_alerts: receiveAlerts,
          is_active: isActive,
          role_id: roleId,
        },
        { headers }
      )
      .then(({ data }) => {
        const payload = {
          data: data,
        };
        dispatch(success(payload));
      })
      .catch((err) => {
        console.log("ERROR! [updateUserAction] " + err.message);
        const payload = {
          errorMessage: err.message,
        };
        dispatch(error(payload));
      });
  };
};

const loading = () => ({
  type: UPDATE_USER,
});

const success = (data) => ({
  type: UPDATE_USER_SUCCESS,
  payload: {
    ...data,
  },
});

const error = (data) => ({
  type: UPDATE_USER_ERROR,
  payload: {
    ...data,
  },
});

export const reset = () => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_RESET,
    });
  };
};
