import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Snackbar,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";

import { addLocation } from "../../actions/addLocation";
import { updateLocation } from "../../actions/updateLocation";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddLocation = ({ handleClose, open, updateData, mode = "add" }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const addLocationResult = useSelector((state) => state.addLocation);
  const updateLocationResult = useSelector((state) => state.updateLocation);

  const [globalName, setGlobalName] = useState(null);
  const [locationAlias, setLocationAlias] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  useEffect(() => {
    if (mode === "add") {
      setGlobalName();
      setLocationAlias();
      setLatitude();
      setLongitude();
    } else {
      setGlobalName(updateData?.globalName);
      setLocationAlias(updateData?.locationAlias);
      setLatitude(updateData?.latitude);
      setLongitude(updateData?.longitude);
    }
  }, [mode, updateData]);

  const handleAddLocation = (event) => {
    event.preventDefault();
    if (mode === "add") {
      if (window.confirm("Are you sure to add?")) {
        dispatch(
          addLocation({
            globalName,
            locationAlias,
            latitude: Number(latitude),
            longitude: Number(longitude),
          })
        );
        setTimeout(handleClose, 1000);
      }
    } else if (mode === "update") {
      if (window.confirm("Are you sure to update?")) {
        dispatch(
          updateLocation(updateData?.locationId, {
            locationAlias,
          })
        );
        setTimeout(handleClose, 1000);
      }
    }
  };

  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState();

  useEffect(() => {
    if (addLocationResult.data && mode === "add") {
      setOpenSnack(true);
      setMessage(addLocationResult.data.result);
    }
    if (updateLocationResult.data && mode === "update") {
      setOpenSnack(true);
      setMessage(updateLocationResult.data.result);
    }

    return () => {
      setOpenSnack(false);
      setMessage();
    };
  }, [addLocationResult, message, mode, updateLocationResult]);

  return (
    <>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={() => setOpenSnack(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert severity="info">
          <Typography variant="h5" style={{ color: "#fff" }}>
            {message}
          </Typography>
        </Alert>
      </Snackbar>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          {mode === "add" ? "Add Location" : "Update Location"}
        </DialogTitle>
        <form
          className={classes.form}
          autoComplete="off"
          onSubmit={handleAddLocation}
        >
          <DialogContent>
            <div>
              {mode === "add" && (
                <FormControl className={classes.formControl}>
                  <TextField
                    required
                    label="Global Location Name"
                    variant="outlined"
                    value={globalName}
                    onChange={(event) => {
                      setGlobalName(event.target.value);
                    }}
                  />
                </FormControl>
              )}
              <FormControl className={classes.formControl}>
                <TextField
                  label="Location Alias"
                  style={{ marginLeft: 10 }}
                  variant="outlined"
                  value={locationAlias}
                  onChange={(event) => {
                    setLocationAlias(event.target.value);
                  }}
                />
              </FormControl>
            </div>
            {mode === "add" && (
              <div>
                <FormControl className={classes.formControl}>
                  <TextField
                    required
                    label="Latitude"
                    variant="outlined"
                    value={latitude}
                    onChange={(event) => {
                      setLatitude(event.target.value);
                    }}
                  />
                </FormControl>

                <FormControl className={classes.formControl}>
                  <TextField
                    required
                    label="Longitude"
                    variant="outlined"
                    style={{ marginLeft: 10 }}
                    value={longitude}
                    onChange={(event) => {
                      setLongitude(event.target.value);
                    }}
                  />
                </FormControl>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    "& > *": {
      margin: theme.spacing(1),
      // width: "100%",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default AddLocation;
