import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Typography, Breadcrumbs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { loadSummary } from "../../actions/loadSummary";
import { loadLocationState } from "../../actions/loadLocationState";

import DashboardLayout from "../../layout/Dashboard";
import ComponentWrapper from "../../components/ComponentWrapper";

import FacilitiesSearchable from "./components/FacilitiesSearchable";
import MapWithMarkers from "./components/MapWithMarkers";
import FacilityStatus from "./components/FacilityStatus";

function Summary(props) {
  const {
    match,
    title: defaultTitle,
    loadSummary: loadSummaryFn,
    loadLocationState: loadLocationStateFn,
    locationList,
    loading,
    error,
    errorMessage
  } = props;
  const classes = useStyles();

  const [title, setTitle] = useState(null);
  const [currentFacility, setCurrentFacility] = useState(null);

  useEffect(() => {
    if (locationList.length > 1) {
      setCurrentFacility(0);
    } else if (locationList.length === 1) {
      setCurrentFacility(locationList[0].id);
    }
  }, [locationList]);

  useEffect(() => {
    let clear;
    loadLocationStateFn();
    clear = setInterval(() => {
      loadLocationStateFn();
    }, 60000);
    return () => {
      clearInterval(clear);
    };
  }, [match, loadLocationStateFn]); // props.match to clear interval on route change

  useEffect(() => {
    let clear;
    if (currentFacility != null) {
      loadSummaryFn(currentFacility);
      clear = setInterval(() => {
        loadSummaryFn(currentFacility);
      }, 60000);
    }
    return () => {
      clearInterval(clear);
    };
  }, [match, currentFacility, loadSummaryFn]); // props.match to clear interval on route change

  const handleFacilityClick = id => {
    setCurrentFacility(id);
  };

  useEffect(() => {
    // Set Page Title
    const TitleBreadcrumb = () => {
      return (
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography style={{ color: "white" }}>{defaultTitle}</Typography>
        </Breadcrumbs>
      );
    };
    setTitle(TitleBreadcrumb);
    return () => {
      setTitle(null);
    };
  }, [defaultTitle]);

  return (
    <DashboardLayout title={title} documentTitle="Summary">
      <ComponentWrapper
        loading={loading}
        error={error}
        errorMessage={errorMessage}
      >
        <Grid container className={classes.container}>
          <Grid item xs={12} md={3} className={classes.list}>
            <FacilitiesSearchable
              selection={currentFacility}
              handleClick={handleFacilityClick}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.map}>
            <MapWithMarkers handleClick={handleFacilityClick} />
          </Grid>
          <Grid item xs={12} md={3} className={classes.status}>
            <FacilityStatus />
          </Grid>
        </Grid>
      </ComponentWrapper>
    </DashboardLayout>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1
  },
  list: {
    height: "100%"
  },
  map: {
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      minHeight: 300,
      padding: 0
    }
  },
  status: {
    height: "100%",
    backgroundColor: "white"
  }
}));

const mapStateToProps = state => {
  return {
    locationList: state.locations.data,
    loading: state.locations.loading,
    error: state.locations.error,
    errorMessage: state.locations.errorMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadLocationState: () => {
      dispatch(loadLocationState());
    },
    loadSummary: id => {
      dispatch(loadSummary(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Summary));
