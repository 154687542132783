import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Link as RouterLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
// import { useSnackbar } from "notistack";

import DashboardLayout from "../../layout/Dashboard";
import ComponentWrapper from "../../components/ComponentWrapper";
import UnitMetrics from "./UnitMetrics";

import { loadUnitMetrics } from "../../actions/loadUnitMetrics";

function Unit(props) {
  const {
    match,
    title: defaultTitle,
    loadUnitMetrics: loadUnitMetricsFn,
    unitMetrics,
    loading,
    error,
    errorMessage
  } = props;
  const classes = useStyles();
  // const { enqueueSnackbar } = useSnackbar();
  const [title, setTitle] = useState(null);

  useEffect(() => {
    loadUnitMetricsFn(match.params.wid, match.params.uid);

    let intr = setInterval(() => {
      loadUnitMetricsFn(match.params.wid, match.params.uid);
    }, 60000);
    return () => {
      clearInterval(intr);
    };
  }, [match.params, loadUnitMetricsFn]);

  // useEffect(() => {
  //   if (error && errorMessage) {
  //     enqueueSnackbar(errorMessage, { variant: "error" });
  //   }
  // }, [error]);

  useEffect(() => {
    // Set Page Title
    let unitName, locationName;
    if (unitMetrics[0]) {
      if (unitMetrics[0].data[0]["Unit Alias"])
        unitName = unitMetrics[0].data[0]["Unit Alias"];
      else unitName = unitMetrics[0].data[0]["Unit Name"];
      if (unitMetrics[0].data[0]["Location Alias"])
        locationName = unitMetrics[0].data[0]["Location Alias"];
      else locationName = unitMetrics[0].data[0]["Location Name"];

      const TitleBreadcrumb = () => {
        let warehouseRedirect = "/warehouse/" + match.params.wid;
        return (
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
              style={{ color: "white" }}
              component={RouterLink}
              to={warehouseRedirect}
            >
              {locationName}
            </Link>

            <Typography style={{ color: "white" }}>{unitName}</Typography>
          </Breadcrumbs>
        );
      };
      setTitle(TitleBreadcrumb);
    } else {
      const TitleBreadcrumbAlt = () => {
        return (
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography style={{ color: "white" }}>
              {defaultTitle} {match.params.uid}
            </Typography>
          </Breadcrumbs>
        );
      };
      setTitle(TitleBreadcrumbAlt);
    }
    return () => {
      setTitle(null);
    };
  }, [unitMetrics, match.params, defaultTitle]);

  return (
    <DashboardLayout title={title} documentTitle="Unit Metrics">
      <ComponentWrapper
        loading={loading}
        error={error}
        errorMessage={errorMessage}
      >
        <Grid container spacing={0} style={{ height: "100%" }}>
          <Grid item sm={12} className={classes.gridItem}>
            <UnitMetrics data={unitMetrics} />
          </Grid>
        </Grid>
      </ComponentWrapper>
    </DashboardLayout>
  );
}

const useStyles = makeStyles(theme => ({
  gridItem: {
    height: "100%",
    padding: "16px"
  }
}));

const mapStateToProps = state => {
  return {
    unitMetrics: state.unit.unitMetrics,
    loading: state.unit.loading,
    error: state.unit.error,
    errorMessage: state.unit.errorMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadUnitMetrics: (wid, uid) => {
      dispatch(loadUnitMetrics(wid, uid));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Unit));
