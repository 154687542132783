import { combineReducers } from "redux";

import roleAccessReducer from "./roleAccessReducer";
import locationsReducer from "./locationsReducer";
import locationStateReducer from "./locationStateReducer";
import notificationsReducer from "./notificationsReducer";
import summaryReducer from "./summaryReducer";
import warehouseReducer from "./warehouseReducer";
import unitReducer from "./unitReducer";
import powerReducer from "./powerReducer";
import metricChartReducer from "./metricChartReducer";
import metricReportReducer from "./metricReportReducer";
import alertsChartReducer from "./alertsChartReducer";
import alertsTableReducer from "./alertsTableReducer";
import alertsRectActionReducer from "./alertsRectActionReducer";
// import alertsActionReducer from "./alertsActionReducer";
import sensorsReducer from "./sensorsReducer";
import usersReducer from "./usersReducer";
import userRolesReducer from "./userRolesReducer";
import sensorTypesReducer from "./sensorTypesReducer";
import addUserReducer from "./addUserReducer";
import addSensorReducer from "./addSensorReducer";
import unitsReducer from "./unitsReducer";
import updateSensorReducer from "./updateSensorReducer";
import deleteSensorReducer from "./deleteSensorReducer";
import updateUserReducer from "./updateUserReducer";
import deleteUserReducer from "./deleteUserReducer";
import addLocationReducer from "./addLocationReducer";
import updateLocationReducer from "./updateLocationReducer";
import addUnitReducer from "./addUnitReducer";
import updateUnitReducer from "./updateUnitReducer";
import deleteUnitReducer from "./deleteUnitReducer";
import userLocationsReducer from "./userLocationsReducer";
import addUserLocationsReducer from "./addUserLocationsReducer";
import deleteUserLocationsReducer from "./deleteUserLocationsReducer";
import setupLocationsReducer from "./setupLocationsReducer";

const rootReducer = combineReducers({
  roleAccess: roleAccessReducer,
  locations: locationsReducer,
  locationState: locationStateReducer,
  notifications: notificationsReducer,
  summary: summaryReducer,
  warehouse: warehouseReducer,
  unit: unitReducer, // Unit Data
  power: powerReducer,
  metricChart: metricChartReducer,
  metricReport: metricReportReducer,
  alertsChart: alertsChartReducer,
  alertsTable: alertsTableReducer,
  alertsRectAction: alertsRectActionReducer,
  // alertsAction: alertsActionReducer
  units: unitsReducer, // Unit List
  sensorTypes: sensorTypesReducer,
  sensors: sensorsReducer,
  addSensor: addSensorReducer,
  updateSensor: updateSensorReducer,
  deleteSensor: deleteSensorReducer,
  userRoles: userRolesReducer,
  users: usersReducer,
  addUser: addUserReducer,
  updateUser: updateUserReducer,
  deleteUser: deleteUserReducer,
  addLocation: addLocationReducer,
  updateLocation: updateLocationReducer,
  addUnit: addUnitReducer,
  updateUnit: updateUnitReducer,
  updateUnitReducer,
  deleteUnit: deleteUnitReducer,
  userLocations: userLocationsReducer,
  addUserLocations: addUserLocationsReducer,
  deleteUserLocations: deleteUserLocationsReducer,
  setupLocations: setupLocationsReducer, // Used instead of locations in master setup pages
});

export default rootReducer;
