import {
  DELETE_SENSOR,
  DELETE_SENSOR_SUCCESS,
  DELETE_SENSOR_ERROR,
  DELETE_SENSOR_RESET,
} from "../actions/types";

export const initialState = {
  loading: false,
  data: null,
};

function deleteSensorReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_SENSOR:
      return {
        ...state,
        data: null,
        loading: true,
        error: false,
      };
    case DELETE_SENSOR_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };
    case DELETE_SENSOR_ERROR:
      return {
        ...state,
        data: null,
        loading: true,
        error: true,
        errorMessage: action.payload.errorMessage,
      };
    case DELETE_SENSOR_RESET:
      return {
        ...state,
        data: null,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
}

export default deleteSensorReducer;
