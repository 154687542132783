import axios from "axios";

import {
  ADD_SENSOR,
  ADD_SENSOR_SUCCESS,
  ADD_SENSOR_ERROR,
  ADD_SENSOR_RESET,
} from "./types";

export const addSensor = ({
  unitId,
  sensorNumber,
  alias,
  subTypeId,
  upperLimitEnable,
  upperLimit,
  lowerLimitEnable,
  lowerLimit,
  metricUnit,
  aggregation,
  sendAlerts,
  isActive,
}) => {
  return (dispatch) => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/sensors`,
        {
          Unit_id: unitId,
          SensorAlias: alias,
          SensorSubType_id: subTypeId,
          Sensor_number: sensorNumber,
          upper_limit_is_applicable: upperLimitEnable,
          UpperLimit: upperLimit,
          lower_limit_is_applicable: lowerLimitEnable,
          LowerLimit: lowerLimit,
          MetricUnit: metricUnit,
          Aggregation: aggregation,
          SendAlert: sendAlerts,
          is_active: isActive,
        },
        { headers }
      )
      .then(({ data }) => {
        const payload = {
          data: data,
        };
        dispatch(success(payload));
      })
      .catch((err) => {
        console.log("ERROR! [addSensorAction] " + err.message);
        const payload = {
          errorMessage: err.message,
        };
        dispatch(error(payload));
      });
  };
};

const loading = () => ({
  type: ADD_SENSOR,
});

const success = (data) => ({
  type: ADD_SENSOR_SUCCESS,
  payload: {
    ...data,
  },
});

const error = (data) => ({
  type: ADD_SENSOR_ERROR,
  payload: {
    ...data,
  },
});

export const reset = () => {
  return (dispatch) => {
    dispatch({
      type: ADD_SENSOR_RESET,
    });
  };
};
