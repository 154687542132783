import axios from "axios";

import { LOAD_USERS, LOAD_USERS_SUCCESS, LOAD_USERS_ERROR } from "./types";

export const loadUsers = () => {
  return (dispatch) => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/users`, { headers })
      .then(({ data }) => {
        if (data.length > 0) {
          dispatch(success({ data }));
        } else throw Error("No data found");
      })
      .catch((err) => {
        console.log("ERROR! [loadUsers] " + err.message);
        const payload = {
          errorMessage: err.message,
        };
        dispatch(error(payload));
      });
  };
};

const loading = () => ({
  type: LOAD_USERS,
});

const success = (data) => ({
  type: LOAD_USERS_SUCCESS,
  payload: {
    ...data,
  },
});

const error = (data) => ({
  type: LOAD_USERS_ERROR,
  payload: {
    ...data,
  },
});
