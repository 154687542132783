import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  DialogContent,
  InputAdornment,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";

import { loadSetupLocations } from "../../actions/loadSetupLocations";
import { loadUnits } from "../../actions/loadUnits";
import { loadSensorTypes } from "../../actions/loadSensorTypes";
import { addSensor } from "../../actions/addSensor";
import { updateSensor } from "../../actions/updateSensor";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddSensor = ({ handleClose, open, updateData, mode = "add" }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.setupLocations.data);
  const sensorTypes = useSelector((state) => state.sensorTypes.data);
  const units = useSelector((state) => state.units.data);
  const addSensorResult = useSelector((state) => state.addSensor);
  const updateSensorResult = useSelector((state) => state.updateSensor);

  const [location, setLocation] = useState(null);
  const [sensorType, setSensorType] = useState(null);
  const [unit, setUnit] = useState(null);
  const [upperLimitChecked, setUpperLimitChecked] = useState(false);
  const [upperLimit, setUpperLimit] = useState(null);
  const [lowerLimitChecked, setLowerLimitChecked] = useState(false);
  const [lowerLimit, setLowerLimit] = useState(null);
  // const [metricUnit, setMetricUnit] = useState(null);
  const [aggregation, setAggregation] = useState(15);
  const [alias, setAlias] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [sendAlerts, setSendAlerts] = useState(true);
  const [globalId, setGlobalId] = useState(null);

  useEffect(() => {
    dispatch(loadSetupLocations());
    dispatch(loadSensorTypes());
  }, [dispatch]);

  useEffect(() => {
    if (location) {
      dispatch(loadUnits(location));
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (mode === "add") {
      setUnit();
      setAggregation(15);
      setAlias();
      setLowerLimit();
      setLowerLimitChecked(false);
      setUpperLimit();
      setUpperLimitChecked(false);
      setSensorType();
      setIsActive(true);
      setSendAlerts(true);
      setGlobalId();
    } else {
      setUnit(updateData?.unit);
      setAggregation(updateData?.aggregation);
      setAlias(updateData?.alias);
      // setMetricUnit(updateData?.metricUnit);
      setLowerLimit(updateData?.lowerLimit);
      setLowerLimitChecked(updateData?.lowerLimitChecked);
      setUpperLimit(updateData?.upperLimit);
      setUpperLimitChecked(updateData?.upperLimitChecked);
      setSensorType(updateData?.sensorType);
      setIsActive(updateData?.isActive);
      setSendAlerts(updateData?.sendAlerts);
      setGlobalId(updateData?.sensorId);
    }
  }, [mode, updateData]);

  const onSubmit = (event) => {
    event.preventDefault();
    if (sensorTypes[sensorType]?.subtype_id) {
      if (window.confirm("Are you sure to add?")) {
        dispatch(
          addSensor({
            // globalName: `${units[unit]?.global_unit_name}_${globalId}_${sensorTypes[sensorType]?.sensor_subtype_suffix}`,
            unitId: units[unit]?.unit_id,
            sensorNumber: Number(globalId),
            alias,
            subTypeId: Number(sensorTypes[sensorType]?.subtype_id),
            upperLimitEnable: upperLimitChecked ?? false,
            upperLimit: Number(upperLimit),
            lowerLimitEnable: lowerLimitChecked ?? false,
            lowerLimit: Number(lowerLimit),
            // metricUnit: metricUnit,
            aggregation: Number(aggregation),
            sendAlerts: sendAlerts ?? false,
            isActive: isActive ?? false,
          })
        );
        setTimeout(handleClose, 1000);
      }
    } else if (mode === "update") {
      if (window.confirm("Are you sure to update?")) {
        dispatch(
          updateSensor(updateData?.sensorId, {
            // globalName: `${units[unit]?.global_unit_name}_${globalId}_${sensorTypes[sensorType]?.sensor_subtype_suffix}`,
            alias,
            subTypeId: Number(sensorTypes[sensorType]?.subtype_id),
            upperLimitEnable: upperLimitChecked,
            upperLimit: Number(upperLimit),
            lowerLimitEnable: lowerLimitChecked,
            lowerLimit: Number(lowerLimit),
            // metricUnit: metricUnit,
            aggregation: Number(aggregation),
            sendAlerts,
            isActive: isActive,
          })
        );
        setTimeout(handleClose, 1000);
      }
    }
  };

  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState();

  useEffect(() => {
    if (addSensorResult.data && mode === "add") {
      setOpenSnack(true);
      setMessage(addSensorResult.data.result);
    }
    if (updateSensorResult.data && mode === "update") {
      setOpenSnack(true);
      setMessage(updateSensorResult.data.result);
    }

    return () => {
      setOpenSnack(false);
      setMessage();
    };
  }, [addSensorResult, message, mode, updateSensorResult]);

  return (
    <>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={() => setOpenSnack(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert severity="info">
          <Typography variant="h5" style={{ color: "#fff" }}>
            {message}
          </Typography>
        </Alert>
      </Snackbar>
      <Dialog onClose={handleClose} open={open} fullWidth>
        <DialogTitle>
          {mode === "add" ? "Add Sensor" : "Update Sensor"}
          {updateData?.name && ` (${updateData?.name})`}
        </DialogTitle>
        <form className={classes.form} autoComplete="off" onSubmit={onSubmit}>
          <DialogContent>
            {mode === "add" && (
              <>
                <div>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    required
                  >
                    <InputLabel htmlFor="location">Location</InputLabel>
                    <Select
                      native
                      variant="outlined"
                      value={location}
                      onChange={(event) => {
                        setLocation(event.target.value);
                      }}
                      inputProps={{
                        name: "location",
                        id: "location",
                      }}
                    >
                      <option aria-label="None" value="" />
                      {locations.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    required
                  >
                    <InputLabel htmlFor="unit">Unit</InputLabel>
                    <Select
                      native
                      value={unit}
                      onChange={(event) => {
                        setUnit(event.target.value);
                      }}
                      inputProps={{
                        name: "unit",
                        id: "unit",
                      }}
                    >
                      <option aria-label="None" value="" />
                      {units.map((item, key) => (
                        <option
                          value={key}
                          // value={item["unit_id"]} FIXME:
                        >
                          {item["global_unit_name"]}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  {/* {JSON.stringify(sensorTypes)} */}
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    required
                  >
                    <InputLabel htmlFor="unit">Sensor Type</InputLabel>
                    <Select
                      native
                      value={sensorType}
                      onChange={(event) => {
                        setSensorType(event.target.value);
                      }}
                      inputProps={{
                        name: "sensortype",
                        id: "sensortype",
                      }}
                    >
                      <option aria-label="None" value="" />
                      {sensorTypes.map((item, key) => (
                        // <option value={item["subtype_id"]}> FIXME:
                        <option value={key}>{item["sensor_type"]}</option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </>
            )}
            <div>
              {/* <FormControl className={classes.formControl}>
              <TextField
                required
                label="Metric Unit"
                variant="outlined"
                value={metricUnit}
                placeholder="KWh"
                onChange={(event) => {
                  setMetricUnit(event.target.value);
                }}
              />
            </FormControl> */}

              <FormControl className={classes.formControl}>
                <TextField
                  required
                  label="Aggregation"
                  style={{ marginLeft: 10 }}
                  variant="outlined"
                  type="number"
                  value={aggregation}
                  onChange={(event) => {
                    setAggregation(event.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl} required>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={upperLimitChecked}
                      onChange={(event) => {
                        setUpperLimitChecked(event.target.checked);
                      }}
                    />
                  }
                  label="Upper Limit"
                  labelPlacement="end"
                />
              </FormControl>
              {upperLimitChecked && (
                <FormControl
                  className={classes.formControl}
                  style={{ marginLeft: 10 }}
                >
                  <TextField
                    type="number"
                    label="Upper Limit Value"
                    variant="outlined"
                    value={upperLimit}
                    onChange={(event) => {
                      setUpperLimit(event.target.value);
                    }}
                  />
                </FormControl>
              )}
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={lowerLimitChecked}
                      onChange={(event) => {
                        setLowerLimitChecked(event.target.checked);
                      }}
                    />
                  }
                  label="Lower Limit"
                  labelPlacement="end"
                />
              </FormControl>
              {lowerLimitChecked && (
                <FormControl
                  className={classes.formControl}
                  style={{ marginLeft: 10 }}
                >
                  <TextField
                    type="number"
                    label="Lower Limit Value"
                    variant="outlined"
                    value={lowerLimit}
                    onChange={(event) => {
                      setLowerLimit(event.target.value);
                    }}
                  />
                </FormControl>
              )}
            </div>
            <div>
              <FormControl
                className={classes.formControl}
                style={{ marginLeft: 10 }}
              >
                <TextField
                  label="Sensor Alias"
                  variant="outlined"
                  value={alias}
                  onChange={(event) => {
                    setAlias(event.target.value);
                  }}
                />
              </FormControl>
              {/* </div>
          <div> */}
              <FormControl
                className={classes.formControl}
                style={{ marginLeft: 10 }}
              >
                <TextField
                  type="number"
                  required
                  label="Global Sensor ID"
                  variant="outlined"
                  disabled={mode === "update"}
                  value={globalId}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {units[unit]?.global_unit_name}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {sensorTypes[sensorType]?.sensor_subtype_suffix}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    style: { textAlign: "center" },
                  }}
                  onChange={(event) => {
                    setGlobalId(event.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={sendAlerts}
                      onChange={(event) => {
                        setSendAlerts(event.target.checked);
                      }}
                    />
                  }
                  label="Send Alerts"
                  labelPlacement="end"
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isActive}
                      onChange={(event) => {
                        setIsActive(event.target.checked);
                      }}
                    />
                  }
                  label="Sensor Active"
                  labelPlacement="end"
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" type="submit" s>
              Save
            </Button>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    "& > *": {
      margin: theme.spacing(1),
      // width: "100%",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default AddSensor;
