import axios from "axios";

import {
  UPDATE_LOCATION,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_ERROR,
  UPDATE_LOCATION_RESET,
} from "./types";

export const updateLocation = (id, { locationAlias }) => {
  return (dispatch) => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/locations/${id}`,
        {
          location_alias: locationAlias,
        },
        { headers }
      )
      .then(({ data }) => {
        const payload = {
          data: data,
        };
        dispatch(success(payload));
      })
      .catch((err) => {
        console.log("ERROR! [updateLocationAction] " + err.message);
        const payload = {
          errorMessage: err.message,
        };
        dispatch(error(payload));
      });
  };
};

const loading = () => ({
  type: UPDATE_LOCATION,
});

const success = (data) => ({
  type: UPDATE_LOCATION_SUCCESS,
  payload: {
    ...data,
  },
});

const error = (data) => ({
  type: UPDATE_LOCATION_ERROR,
  payload: {
    ...data,
  },
});

export const reset = () => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOCATION_RESET,
    });
  };
};
