import { createMuiTheme } from "@material-ui/core";

import palette from "./palette";
import typography from "./typography";
// import overrides from './overrides';

const theme = createMuiTheme({
  palette,
  typography,
  //   overrides
  overrides: {
    MuiTableSortLabel: {
      root: {
        color: "#fff",
        "&:focus": {
          color: "#aaa",
        },
        "&:hover": {
          color: "#aaa",
        },
      },
      active: {
        color: "#aaa !important",
      },
      icon: {
        color: "#aaa !important",
      },
    },
  },
});

export default theme;
