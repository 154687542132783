import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  DialogContent,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";

import { loadUserRoles } from "../../actions/loadUserRoles";
import { addUser } from "../../actions/addUser";
import { updateUser } from "../../actions/updateUser";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddUser = ({ handleClose, open, updateData, mode = "add" }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userRoles = useSelector((state) => state.userRoles.data);
  const addUserResult = useSelector((state) => state.addUser);
  const updateUserResult = useSelector((state) => state.updateUser);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [userName, setUserName] = useState(null);
  const [password, setPassword] = useState(null);
  const [receiveAlerts, setReceiveAlerts] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [roleId, setRoleId] = useState();

  useEffect(() => {
    dispatch(loadUserRoles());
  }, [dispatch]);

  useEffect(() => {
    if (mode === "add") {
      setFirstName();
      setLastName();
      setUserName();
      setReceiveAlerts(true);
      setIsActive(true);
      setRoleId();
    } else {
      setFirstName(updateData?.firstName);
      setLastName(updateData?.lastName);
      setUserName(updateData?.userName);
      setReceiveAlerts(updateData?.receiveAlerts);
      setIsActive(updateData?.isActive);
      setRoleId(updateData?.roleId);
    }
  }, [updateData, mode]);

  const handleAddUser = (event) => {
    event.preventDefault();
    if (mode === "add") {
      if (window.confirm("Are you sure to add?")) {
        dispatch(
          addUser({
            firstName,
            lastName,
            password,
            userName,
            receiveAlerts,
            isActive,
            roleId,
          })
        );
        setTimeout(handleClose, 1000);
      }
    } else if (mode === "update") {
      if (window.confirm("Are you sure to update?")) {
        console.log(updateData);
        dispatch(
          updateUser(updateData?.userId, {
            userName,
            firstName,
            lastName,
            // password,
            receiveAlerts,
            isActive,
            roleId,
          })
        );
        setTimeout(handleClose, 1000);
      }
    }
  };

  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState();

  useEffect(() => {
    if (addUserResult.data && mode === "add") {
      setOpenSnack(true);
      setMessage(addUserResult.data.result);
    }
    if (updateUserResult.data && mode === "update") {
      setOpenSnack(true);
      setMessage(updateUserResult.data.result);
    }

    return () => {
      setOpenSnack(false);
      setMessage();
    };
  }, [addUserResult, message, mode, updateUserResult]);

  return (
    <>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={() => setOpenSnack(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert severity="info">
          <Typography variant="h5" style={{ color: "#fff" }}>
            {message}
          </Typography>
        </Alert>
      </Snackbar>
      <Dialog onClose={handleClose} open={open} fullWidth>
        <DialogTitle>{mode === "add" ? "Add User" : "Update User"}</DialogTitle>
        <form
          className={classes.form}
          autoComplete="off"
          onSubmit={handleAddUser}
        >
          <DialogContent>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                  required
                  label="First Name"
                  variant="outlined"
                  value={firstName}
                  onChange={(event) => {
                    setFirstName(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  required
                  label="Last Name"
                  style={{ marginLeft: 10 }}
                  variant="outlined"
                  value={lastName}
                  onChange={(event) => {
                    setLastName(event.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                  required
                  label="User Name"
                  variant="outlined"
                  value={userName}
                  onChange={(event) => {
                    setUserName(event.target.value);
                  }}
                />
              </FormControl>
              {mode === "add" && (
                <FormControl className={classes.formControl}>
                  <TextField
                    required
                    type="password"
                    label="Password"
                    variant="outlined"
                    style={{ marginLeft: 10 }}
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />
                </FormControl>
              )}
            </div>
            <div>
              <FormControl
                required
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel id="role">User Role</InputLabel>
                <Select
                  native
                  required
                  variant="outlined"
                  value={roleId}
                  onChange={(event) => {
                    setRoleId(event.target.value);
                  }}
                  labelId="role"
                  label="User Role"
                >
                  <option aria-label="None" value="" />
                  {/* <option value={0}>CXO</option> */}
                  {userRoles?.map((item) => (
                    <option value={item.role_id}>{item.role}</option>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl required className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={receiveAlerts}
                      onChange={(event) => {
                        setReceiveAlerts(event.target.checked);
                      }}
                    />
                  }
                  label="Receive Alerts"
                  labelPlacement="end"
                />
              </FormControl>
            </div>
            <div>
              <FormControl required className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isActive}
                      onChange={(event) => {
                        setIsActive(event.target.checked);
                      }}
                    />
                  }
                  label="Active"
                  labelPlacement="end"
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    "& > *": {
      margin: theme.spacing(1),
      // width: "100%",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default AddUser;
