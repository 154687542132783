import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Typography,
  Paper,
  Toolbar,
  Button,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";

import { tableIcons } from "../../helpers/tableIcons";
import DashboardLayout from "../../layout/Dashboard";
import breadcrumbGenerator from "../../helpers/breadcrumbGenerator";
import { loadUsers } from "../../actions/loadUsers";
import { deleteUser } from "../../actions/deleteUser";
import AddUser from "./AddUser";
import UserLocations from "./UserLocations";
import { reset as resetAdd } from "../../actions/addUser";
import { reset as resetUpdate } from "../../actions/updateUser";

function Users(props) {
  const { title } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.data);

  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const [userId, setUserId] = useState();
  const [userName, setUserName] = useState();
  const [updateData, setUpdateData] = useState({});

  const [buttonMode, setButtonMode] = useState("add");

  const handleOpen = () => {
    setButtonMode("add");
    setOpen(true);
  };
  const handleUserLocations = (id, name) => {
    setUserId(id);
    setUserName(name);
    setView(true);
  };

  const handleEdit = (id) => {
    setButtonMode("update");
    setUpdateData({
      userName: users.find((o) => o.user_id === id)?.user_name,
      firstName: users.find((o) => o.user_id === id)?.first_name,
      lastName: users.find((o) => o.user_id === id)?.last_name,
      receiveAlerts: users.find((o) => o.user_id === id)?.is_receive_alerts,
      isActive: users.find((o) => o.user_id === id)?.is_active,
      roleId: users.find((o) => o.user_id === id)?.role_id,
      userId: id,
    });
    setOpen(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      dispatch(deleteUser(id));
      setTimeout(() => {
        dispatch(loadUsers());
      }, 1000);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      dispatch(loadUsers());
      dispatch(resetAdd());
      dispatch(resetUpdate());
    }
  }, [dispatch, open]);

  return (
    <DashboardLayout
      title={breadcrumbGenerator([{ title: title }])}
      documentTitle="Users"
    >
      <div
        style={{
          flexGrow: 1,
          height: "100%",
          padding: "16px",
          overflowY: "scroll",
        }}
      >
        <AddUser
          open={open}
          handleClose={handleClose}
          updateData={updateData}
          mode={buttonMode}
        />

        <UserLocations
          open={view}
          handleClose={() => {
            setView(false);
          }}
          userId={userId}
          userName={userName}
        />

        <Toolbar>
          <Typography variant="h6" id="tableTitle">
            Users
          </Typography>
          <div style={{ flex: "1 1 100%" }} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            style={{ whiteSpace: "nowrap" }}
          >
            Add User
          </Button>
        </Toolbar>
        <Paper className={classes.paper}>
          <MaterialTable
            icons={tableIcons}
            options={{
              headerStyle: { backgroundColor: "black", color: "white" },
              rowStyle: (rowData) => ({
                backgroundColor:
                  // eslint-disable-next-line eqeqeq
                  rowData.is_active == false
                    ? "#BBB"
                    : rowData.tableData.id % 2
                    ? "#EEE"
                    : "#FFF",
              }),
              paging: false,
              maxBodyHeight: 450,
              draggable: false,
            }}
            title=""
            columns={[
              {
                title: "User Name",
                field: "user_name",
              },
              {
                title: "First Name",
                field: "first_name",
              },
              {
                title: "Last Name",
                field: "last_name",
              },
              {
                title: "Role",
                field: "role",
              },
              {
                title: "Status",
                field: "is_active",
                render: (data) => {
                  // eslint-disable-next-line eqeqeq
                  return data.is_active == true ? "Active" : "Inactive";
                },
              },
              {
                title: "Alerts",
                field: "is_receive_alerts",
                render: (data) => {
                  // eslint-disable-next-line eqeqeq
                  return data.is_receive_alerts == true
                    ? "Enabled"
                    : "Disabled";
                },
              },
              {
                title: "Units Mapped",
                render: (data, key) => {
                  return (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        handleUserLocations(data?.user_id, data?.user_name);
                      }}
                    >
                      View
                    </Button>
                  );
                },
              },
              {
                title: "Modify",
                render: (data, key) => {
                  return (
                    <>
                      <IconButton
                        size="small"
                        onClick={() => handleEdit(data?.user_id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleDelete(data?.user_id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  );
                },
              },
            ]}
            data={users}
          />
        </Paper>
      </div>
    </DashboardLayout>
  );
}

const useStyles = makeStyles((theme) => ({
  form: {},
}));

export default Users;
