import {
  LOAD_SENSORS,
  LOAD_SENSORS_SUCCESS,
  LOAD_SENSORS_ERROR,
} from "../actions/types";

export const initialState = {
  loading: true,
  data: [],
};

function sensorsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SENSORS:
      return {
        ...state,
        data: [],
        loading: true,
        error: false,
      };
    case LOAD_SENSORS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };
    case LOAD_SENSORS_ERROR:
      return {
        ...state,
        data: [],
        loading: true,
        error: true,
        errorMessage: action.payload.errorMessage,
      };
    default:
      return state;
  }
}

export default sensorsReducer;
