import React, { forwardRef } from "react";
import {
  SaveAlt,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  Clear,
  Search,
  ArrowDownward,
} from "@material-ui/icons";

export const tableIcons = {
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),

  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};
