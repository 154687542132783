import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Checkbox,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  InputAdornment,
  DialogContent,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";

import { addUnit } from "../../actions/addUnit";
import { updateUnit } from "../../actions/updateUnit";
import { loadSetupLocations } from "../../actions/loadSetupLocations";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddUnit = ({
  handleClose,
  open,
  updateData,
  mode = "add",
  locationId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const addUnitResult = useSelector((state) => state.addUnit);
  const updateUnitResult = useSelector((state) => state.updateUnit);
  const locations = useSelector((state) => state.setupLocations.data);

  const [building, setBuilding] = useState(null);
  const [floor, setFloor] = useState(null);
  const [area, setArea] = useState(null);
  const [unitAlias, setUnitAlias] = useState(null);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    setOpenSnack(false);
    setMessage();
    if (mode === "add") {
      setBuilding();
      setFloor();
      setArea();
      setUnitAlias();
      setIsActive(true);
    } else {
      setUnitAlias(updateData?.unitAlias);
      setBuilding(updateData?.globalName[2]);
      setFloor(updateData?.globalName[3]);
      setArea(updateData?.globalName[4]);
      setIsActive(updateData?.isActive);
    }
  }, [mode, updateData]);

  useEffect(() => {
    dispatch(loadSetupLocations());
  }, [dispatch]);

  const handleAddUnit = (event) => {
    event.preventDefault();
    if (mode === "add") {
      if (window.confirm("Are you sure to add?")) {
        dispatch(
          addUnit({
            globalName:
              locations?.find((i) => i.id === locationId)
                ?.global_location_name +
              "_" +
              building +
              "_" +
              floor +
              "_" +
              area,
            unitAlias,
            locationId,
            isActive,
          })
        );
        setTimeout(handleClose, 1000);
      }
    } else if (mode === "update") {
      if (window.confirm("Are you sure to update?")) {
        dispatch(
          updateUnit(updateData?.unitId, {
            unitAlias,
            isActive,
          })
        );
        setTimeout(handleClose, 1000);
      }
    }
  };

  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState();

  useEffect(() => {
    if (addUnitResult.data && mode === "add") {
      setOpenSnack(true);
      setMessage(addUnitResult.data.result);
    }
    if (updateUnitResult.data && mode === "update") {
      setOpenSnack(true);
      setMessage(updateUnitResult.data.result);
    }
  }, [addUnitResult, message, mode, updateUnitResult]);

  return (
    <>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={() => setOpenSnack(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert severity="info">
          <Typography variant="h5" style={{ color: "#fff" }}>
            {message}
          </Typography>
        </Alert>
      </Snackbar>
      <Dialog onClose={handleClose} open={open} fullWidth>
        <DialogTitle>{mode === "add" ? "Add Unit" : "Update Unit"}</DialogTitle>
        <form
          className={classes.form}
          autoComplete="off"
          onSubmit={handleAddUnit}
        >
          <DialogContent>
            <Box display="flex" flexDirection="column">
              <FormControl
                className={classes.formControl}
                variant="outlined"
                required
              >
                <InputLabel id="location-label">Location</InputLabel>
                <Select
                  native
                  variant="outlined"
                  value={locationId}
                  disabled
                  labelId="location-label"
                  label="Location"
                >
                  <option aria-label="None" value="" />

                  {locations.map((item) => (
                    <option value={item.id}>{item.global_location_name}</option>
                  ))}
                </Select>
              </FormControl>
              {mode === "add" && (
                <Box display="flex">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="building">Building</InputLabel>
                    <Select
                      native
                      required
                      variant="outlined"
                      value={building}
                      onChange={(event) => {
                        setBuilding(event.target.value);
                      }}
                      labelId="building"
                      label="Building"
                    >
                      <option aria-label="None" value="" />

                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                        <option value={`B` + item}>Building {item}</option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="floor">Floor</InputLabel>
                    <Select
                      native
                      required
                      variant="outlined"
                      value={floor}
                      onChange={(event) => {
                        setFloor(event.target.value);
                      }}
                      labelId="floor"
                      label="Floor"
                    >
                      <option aria-label="None" value="" />
                      <option value="BB">Basement</option>
                      <option value="GF">Ground Floor</option>
                      <option value="FF">First Floor</option>
                      <option value="SF">Second Floor</option>
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="area">Area</InputLabel>
                    <Select
                      native
                      required
                      variant="outlined"
                      value={area}
                      onChange={(event) => {
                        setArea(event.target.value);
                      }}
                      labelId="area"
                      label="Area"
                    >
                      <option aria-label="None" value="" />
                      <option value="A">AC</option>
                      <option value="B">Back</option>
                      <option value="F">Front</option>
                      <option value="N">Non AC</option>
                    </Select>
                  </FormControl>
                </Box>
              )}
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  required
                  label="Global Unit Name"
                  disabled
                  variant="outlined"
                  value={
                    mode === "add"
                      ? locations?.find((i) => i.id === locationId)
                          ?.global_location_name +
                        "_" +
                        building +
                        "_" +
                        floor +
                        "_" +
                        area
                      : updateData.globalName
                  }
                />
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  label="Unit Alias"
                  variant="outlined"
                  value={unitAlias}
                  onChange={(event) => {
                    setUnitAlias(event.target.value);
                  }}
                />
              </FormControl>
              <div>
                <FormControl required className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={isActive}
                        onChange={(event) => {
                          setIsActive(event.target.checked);
                        }}
                      />
                    }
                    label="Active"
                    labelPlacement="end"
                  />
                </FormControl>
              </div>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    "& > *": {
      // margin: theme.spacing(1),
      // width: "100%",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default AddUnit;
