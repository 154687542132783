import axios from "axios";

import {
  UPDATE_UNIT,
  UPDATE_UNIT_SUCCESS,
  UPDATE_UNIT_ERROR,
  UPDATE_UNIT_RESET,
} from "./types";

export const updateUnit = (id, { unitAlias, isActive }) => {
  return (dispatch) => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/locations/unit/${id}`,
        {
          Unit_alias: unitAlias,
          is_active: isActive,
        },
        { headers }
      )
      .then(({ data }) => {
        const payload = {
          data: data,
        };
        dispatch(success(payload));
      })
      .catch((err) => {
        console.log("ERROR! [updateUnitAction] " + err.message);
        const payload = {
          errorMessage: err.message,
        };
        dispatch(error(payload));
      });
  };
};

const loading = () => ({
  type: UPDATE_UNIT,
});

const success = (data) => ({
  type: UPDATE_UNIT_SUCCESS,
  payload: {
    ...data,
  },
});

const error = (data) => ({
  type: UPDATE_UNIT_ERROR,
  payload: {
    ...data,
  },
});

export const reset = () => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_UNIT_RESET,
    });
  };
};
