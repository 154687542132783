import {
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER_RESET,
} from "../actions/types";

export const initialState = {
  loading: true,
  data: null,
};

function updateUserReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        data: null,
        loading: true,
        error: false,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        data: null,
        loading: true,
        error: true,
        errorMessage: action.payload.errorMessage,
      };
    case UPDATE_USER_RESET:
      return {
        ...state,
        data: null,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
}

export default updateUserReducer;
