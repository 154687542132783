export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_ERROR = "AUTH_LOGIN_ERROR";

export const LOAD_ROLEACCESS = "LOAD_ROLEACCESS";
export const LOAD_ROLEACCESS_SUCCESS = "LOAD_ROLEACCESS_SUCCESS";
export const LOAD_ROLEACCESS_ERROR = "LOAD_ROLEACCESS_ERROR";

export const LOAD_LOCATIONS = "LOAD_LOCATIONS";
export const LOAD_LOCATIONS_SUCCESS = "LOAD_LOCATIONS_SUCCESS";
export const LOAD_LOCATIONS_ERROR = "LOAD_LOCATIONS_ERROR";

export const LOAD_LOCATIONSTATE = "LOAD_LOCATIONSTATE";
export const LOAD_LOCATIONSTATE_SUCCESS = "LOAD_LOCATIONSTATE_SUCCESS";
export const LOAD_LOCATIONSTATE_ERROR = "LOAD_LOCATIONSTATE_ERROR";

export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const LOAD_NOTIFICATIONS_SUCCESS = "LOAD_NOTIFICATIONS_SUCCESS";
export const LOAD_NOTIFICATIONS_ERROR = "LOAD_NOTIFICATIONS_ERROR";

export const LOAD_NOTIFICATIONS_COUNT = "LOAD_NOTIFICATIONS_COUNT";
export const LOAD_NOTIFICATIONS_COUNT_SUCCESS =
  "LOAD_NOTIFICATIONS_COUNT_SUCCESS";
export const LOAD_NOTIFICATIONS_COUNT_ERROR = "LOAD_NOTIFICATIONS_COUNT_ERROR";
export const UPDATE_NOTIFICATIONS_COUNT = "UPDATE_NOTIFICATIONS_COUNT";

export const LOAD_SUMMARY = "LOAD_SUMMARY";
export const LOAD_SUMMARY_SUCCESS = "LOAD_SUMMARY_SUCCESS";
export const LOAD_SUMMARY_ERROR = "LOAD_SUMMARY_ERROR";

export const LOAD_WAREHOUSEDATA = "LOAD_WAREHOUSEDATA";
export const LOAD_WAREHOUSEDATA_SUCCESS = "LOAD_WAREHOUSEDATA_SUCCESS";
export const LOAD_WAREHOUSEDATA_ERROR = "LOAD_WAREHOUSEDATA_ERROR";

export const LOAD_UNITDATA = "LOAD_UNITDATA";
export const LOAD_UNITDATA_SUCCESS = "LOAD_UNITDATA_SUCCESS";
export const LOAD_UNITDATA_ERROR = "LOAD_UNITDATA_ERROR";

export const LOAD_POWER = "LOAD_POWER";
export const LOAD_POWER_SUCCESS = "LOAD_POWER_SUCCESS";
export const LOAD_POWER_ERROR = "LOAD_POWER_ERROR";

export const LOAD_METRICCHART = "LOAD_METRICCHART";
export const LOAD_METRICCHART_SUCCESS = "LOAD_METRICCHART_SUCCESS";
export const LOAD_METRICCHART_ERROR = "LOAD_METRICCHART_ERROR";

export const LOAD_METRICREPORT = "LOAD_METRICREPORT";
export const LOAD_METRICREPORT_SUCCESS = "LOAD_METRICREPORT_SUCCESS";
export const LOAD_METRICREPORT_ERROR = "LOAD_METRICREPORT_ERROR";

export const LOAD_ALERTSTABLE = "LOAD_ALERTSTABLE";
export const LOAD_ALERTSTABLE_SUCCESS = "LOAD_ALERTSTABLE_SUCCESS";
export const LOAD_ALERTSTABLE_ERROR = "LOAD_ALERTSTABLE_ERROR";

export const LOAD_ALERTSCHART = "LOAD_ALERTSCHART";
export const LOAD_ALERTSCHART_SUCCESS = "LOAD_ALERTSCHART_SUCCESS";
export const LOAD_ALERTSCHART_ERROR = "LOAD_ALERTSCHART_ERROR";

export const LOAD_ALERTS_RECT_ACTION = "LOAD_ALERTS_RECT_ACTION";
export const LOAD_ALERTS_RECT_ACTION_SUCCESS =
  "LOAD_ALERTS_RECT_ACTION_SUCCESS";
export const LOAD_ALERTS_RECT_ACTION_ERROR = "LOAD_ALERTS_RECT_ACTION_ERROR";

export const UPDATE_ALERT_STATE = "UPDATE_ALERT_STATE";
export const UPDATE_ALERT_STATE_SUCCESS = "UPDATE_ALERT_STATE_SUCCESS";
export const UPDATE_ALERT_STATE_ERROR = "UPDATE_ALERT_STATE_ERROR";

export const UPDATE_ALERT_ETA_OR_REASON = "UPDATE_ALERT_ETA_OR_REASON";
export const UPDATE_ALERT_ETA_OR_REASON_SUCCESS =
  "UPDATE_ALERT_ETA_OR_REASON_SUCCESS";
export const UPDATE_ALERT_ETA_OR_REASON_ERROR =
  "UPDATE_ALERT_ETA_OR_REASON_ERROR";

export const UPDATE_ALERT_STATE_ETA_OR_REASON =
  "UPDATE_ALERT_STATE_ETA_OR_REASON";
export const UPDATE_ALERT_STATE_ETA_OR_REASON_SUCCESS =
  "UPDATE_ALERT_STATE_ETA_OR_REASON_SUCCESS";
export const UPDATE_ALERT_STATE_ETA_OR_REASON_ERROR =
  "UPDATE_ALERT_STATE_ETA_OR_REASON_ERROR";

export const LOAD_SENSOR_TYPES = "LOAD_SENSOR_TYPES";
export const LOAD_SENSOR_TYPES_SUCCESS = "LOAD_SENSOR_TYPES_SUCCESS";
export const LOAD_SENSOR_TYPES_ERROR = "LOAD_SENSOR_TYPES_ERROR";

export const LOAD_UNITS = "LOAD_UNITS";
export const LOAD_UNITS_SUCCESS = "LOAD_UNITS_SUCCESS";
export const LOAD_UNITS_ERROR = "LOAD_UNITS_ERROR";

export const LOAD_SENSORS = "LOAD_SENSORS";
export const LOAD_SENSORS_SUCCESS = "LOAD_SENSORS_SUCCESS";
export const LOAD_SENSORS_ERROR = "LOAD_SENSORS_ERROR";

export const ADD_SENSOR = "ADD_SENSOR";
export const ADD_SENSOR_SUCCESS = "ADD_SENSOR_SUCCESS";
export const ADD_SENSOR_ERROR = "ADD_SENSOR_ERROR";

export const UPDATE_SENSOR = "UPDATE_SENSOR";
export const UPDATE_SENSOR_SUCCESS = "UPDATE_SENSOR_SUCCESS";
export const UPDATE_SENSOR_ERROR = "UPDATE_SENSOR_ERROR";

export const DELETE_SENSOR = "DELETE_SENSOR";
export const DELETE_SENSOR_SUCCESS = "DELETE_SENSOR_SUCCESS";
export const DELETE_SENSOR_ERROR = "DELETE_SENSOR_ERROR";

export const LOAD_USER_ROLES = "LOAD_USER_ROLES";
export const LOAD_USER_ROLES_SUCCESS = "LOAD_USER_ROLES_SUCCESS";
export const LOAD_USER_ROLES_ERROR = "LOAD_USER_ROLES_ERROR";

export const LOAD_USERS = "LOAD_USERS";
export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const LOAD_USERS_ERROR = "LOAD_USERS_ERROR";

export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const ADD_LOCATION = "ADD_LOCATION";
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS";
export const ADD_LOCATION_ERROR = "ADD_LOCATION_ERROR";

export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_ERROR = "UPDATE_LOCATION_ERROR";

export const ADD_UNIT = "ADD_UNIT";
export const ADD_UNIT_SUCCESS = "ADD_UNTI_SUCCESS";
export const ADD_UNIT_ERROR = "ADD_UNIT_ERROR";

export const UPDATE_UNIT = "UPDATE_UNIT";
export const UPDATE_UNIT_SUCCESS = "UPDATE_UNIT_SUCCESS";
export const UPDATE_UNIT_ERROR = "UPDATE_UNIT_ERROR";

export const DELETE_UNIT = "DELETE_UNIT";
export const DELETE_UNIT_SUCCESS = "DELETE_UNIT_SUCCESS";
export const DELETE_UNIT_ERROR = "DELETE_UNIT_ERROR";

export const LOAD_USER_LOCATIONS = "LOAD_USER_LOCATIONS";
export const LOAD_USER_LOCATIONS_SUCCESS = "LOAD_USER_LOCATIONS_SUCCESS";
export const LOAD_USER_LOCATIONS_ERROR = "LOAD_USER_LOCATIONS_ERROR";

export const ADD_USER_LOCATIONS = "ADD_USER_LOCATIONS";
export const ADD_USER_LOCATIONS_SUCCESS = "ADD_USER_LOCATIONS_SUCCESS";
export const ADD_USER_LOCATIONS_ERROR = "ADD_USER_LOCATIONS_ERROR";

export const DELETE_USER_LOCATIONS = "DELETE_USER_LOCATIONS";
export const DELETE_USER_LOCATIONS_SUCCESS = "DELETE_USER_LOCATIONS_SUCCESS";
export const DELETE_USER_LOCATIONS_ERROR = "DELETE_USER_LOCATIONS_ERROR";

export const LOAD_SETUP_LOCATIONS = "LOAD_SETUP_LOCATIONS";
export const LOAD_SETUP_LOCATIONS_SUCCESS = "LOAD_SETUP_LOCATIONS_SUCCESS";
export const LOAD_SETUP_LOCATIONS_ERROR = "LOAD_SETUP_LOCATIONS_ERROR";

// RESET
export const ADD_LOCATION_RESET = "ADD_LOCATION_RESET";
export const UPDATE_LOCATION_RESET = "UPDATE_LOCATION_RESET";

export const ADD_UNIT_RESET = "ADD_UNIT_RESET";
export const UPDATE_UNIT_RESET = "UPDATE_UNIT_RESET";
export const DELETE_UNIT_RESET = "DELETE_UNIT_RESET";

export const ADD_SENSOR_RESET = "ADD_SENSOR_RESET";
export const UPDATE_SENSOR_RESET = "UPDATE_SENSOR_RESET";
export const DELETE_SENSOR_RESET = "DELETE_SENSOR_RESET";

export const ADD_USER_RESET = "ADD_USER_RESET";
export const UPDATE_USER_RESET = "UPDATE_USER_RESET";
export const DELETE_USER_RESET = "DELETE_USER_RESET";

export const ADD_USER_LOCATIONS_RESET = "ADD_USER_LOCATIONS_RESET";
export const DELETE_USER_LOCATIONS_RESET = "DELETE_USER_LOCATIONS_RESET";
