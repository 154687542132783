import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  DialogContent,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch, useSelector } from "react-redux";

import { loadUserLocations } from "../../actions/loadUserLocations";
import {
  deleteUserLocations,
  reset as resetDelete,
} from "../../actions/deleteUserLocations";
import AddUserLocation from "./AddUserLocation";
import { reset as resetAdd } from "../../actions/addUserLocations";

const UserLocations = ({ handleClose, open, userId, userName }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userLocations = useSelector((state) => state.userLocations.data); // Its actually the userUnits

  const [openNext, setOpenNext] = useState(false);

  useEffect(() => {
    if (!openNext) {
      dispatch(loadUserLocations(userId));
    }
  }, [dispatch, userId, openNext]);

  useEffect(() => {
    if (!openNext) {
      dispatch(resetAdd());
      dispatch(resetDelete());
    }
  }, [dispatch, openNext]);

  // const handleRemove = (unitId) => {
  //   dispatch(deleteUserLocations(userId, [unitId]));
  //   dispatch(loadUserLocations(userId));
  // };

  const handleCloseNext = () => {
    setOpenNext(false);
  };

  return (
    <div>
      {open && (
        <AddUserLocation
          userId={userId}
          open={openNext}
          handleClose={handleCloseNext}
          list={userLocations}
        />
      )}
      <Dialog onClose={handleClose} open={open} fullWidth>
        <Box display="flex" justify="space-between">
          <DialogTitle>User - Units Mapping ({userName})</DialogTitle>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setOpenNext(true)}
            style={{ marginTop: 5 }}
          >
            Edit
          </Button>
        </Box>
        <DialogContent>
          <>
            {userLocations.length !== 0 ? (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Unit Id</TableCell>
                    <TableCell>Unit Name</TableCell>
                    {/* <TableCell>Delete</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userLocations?.map((item) => (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {item.unit_id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.unit_name}
                      </TableCell>
                      {/* <TableCell>
                        <IconButton
                          size="small"
                          onClick={() => {
                            if (window.confirm("Are you sure to delete?")) {
                              handleRemove(item.unit_id);
                            }
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <div>No units mapped!</div>
            )}
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({}));

export default UserLocations;
