import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Scatter } from "react-chartjs-2";

// import "chartjs-plugin-datalabels";
// import "chartjs-plugin-zoom";

import ChartLayout from "./ChartLayout";
import { loadMetricChart } from "../../actions/loadMetricChart";
import { loadMetricReport } from "../../actions/loadMetricReport";
import {
  getTimeRange,
  getAllowedIntervals,
} from "../../helpers/timeRangeSelectionHelper";

function Analytics(props) {
  const classes = useStyles();
  const chartRef = useRef(null);
  const [zoomOption, setZoomOption] = useState(false);
  const [data, setData] = useState({ datasets: [] });
  const [timeRangeOption, setTimeRangeOption] = useState("TODAY");
  const availableIntervals = [];
  const [allowedIntervals, setAllowedIntervals] = useState([]);
  const [dataInterval, setDataInterval] = useState("15M");
  const [chartInterval, setChartInterval] = useState({
    interval: 15,
    intervalUnit: "minute",
  });
  const [chartTime, setChartTime] = useState({ start: null, end: null });
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const timerEff = timer;
    return () => {
      clearInterval(timerEff);
    };
  }, [timer]);

  useEffect(() => {
    handleTimeRangeOption("TODAY");
  }, []);

  useEffect(() => {
    if (props.updated) {
      setChartTime({ start: props.start_time, end: props.end_time });
      switch (dataInterval) {
        case "15M":
          setChartInterval({
            interval: 15,
            intervalUnit: "minute",
          });
          break;
        case "30M":
          setChartInterval({
            interval: 30,
            intervalUnit: "minute",
          });
          break;
        case "1H":
          setChartInterval({
            interval: 1,
            intervalUnit: "hour",
          });
          break;
        case "1D":
          setChartInterval({
            interval: 1,
            intervalUnit: "day",
          });
          break;
        default:
      }
    }
  }, [dataInterval, props.updated, props.start_time, props.end_time]);

  const runLoadChart = (start_time, end_time, interval, option = null) => {
    props.loadMetricChart(props.match.params.sid, start_time, end_time);
    if (option) {
      const t = setTimeout(() => {
        handleTimeRangeOption(option, interval);
      }, 60000);
      setTimer(t);
    } else setTimer(0);
  };

  const handleStartDateChange = (d) => {
    setTimeRangeOption("");
    let {
      allowedIntervals: allIntr,
      defaultInterval: defaultIntr,
    } = getAllowedIntervals(availableIntervals, d, props.end_time);
    setAllowedIntervals(allIntr);
    setDataInterval(defaultIntr);
    runLoadChart(d, props.end_time, defaultIntr);
  };

  const handleEndDateChange = (d) => {
    setTimeRangeOption("");
    let {
      allowedIntervals: allIntr,
      defaultInterval: defaultIntr,
    } = getAllowedIntervals(availableIntervals, props.start_time, d);
    setAllowedIntervals(allIntr);
    setDataInterval(defaultIntr);
    runLoadChart(props.start_time, d, defaultIntr);
  };

  const handleTimeRangeOption = (option, intr = null) => {
    setTimeRangeOption(option);
    let timeRange = getTimeRange(option);
    let {
      allowedIntervals: allIntr,
      defaultInterval: defaultIntr,
    } = getAllowedIntervals(
      availableIntervals,
      timeRange.start_time,
      timeRange.end_time
    );
    setAllowedIntervals(allIntr);
    setDataInterval(defaultIntr);
    runLoadChart(
      timeRange.start_time,
      timeRange.end_time,
      intr ? intr : defaultIntr,
      ["TODAY", "WEEK", "MONTH"].includes(option) ? option : null
    );
  };

  const handleDataInterval = (intr) => {
    setDataInterval(intr);
    runLoadChart(
      props.start_time,
      props.end_time,
      intr,
      ["TODAY", "WEEK", "MONTH"].includes(timeRangeOption)
        ? timeRangeOption
        : null
    );
  };

  useEffect(() => {
    let datasets = [];

    props.chartData.map((item) => {
      datasets.push({
        borderColor: item.within_threshold ? "#3A8447" : "#A2231F",
        openStatus: item.open_status,
        fill: false,
        borderWidth: 30,
        pointRadius: 0,
        showLine: true,
        data: item.point,
      });
    });

    setData({
      datasets: datasets,
    });
  }, [props.chartData]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chartInstance.data = data;
      chartRef.current.chartInstance.update({
        duration: 0,
      });
    }
  }, [data]);

  const options = {
    plugins: {
      datalabels: {
        display: false,
      },
    },
    // tooltips: {
    //   mode: "dataset",
    //   intersect: false,
    //   displayColors : false
    // },
    hover: {
      mode: "dataset",
      // mode: "nearest",
      intersect: true,
    },
    tooltips: {
      mode: "dataset",
      yAlign: "bottom",
      // mode: "index",
      intersect: false,
      displayColors: false,
      enabled: true,
      callbacks: {
        label: function (tooltipItem, data) {
          let val = data.datasets[tooltipItem.datasetIndex].data;
          let time = new Date(val[tooltipItem.index].x);
          let formatted = time.toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
          if (data.datasets[tooltipItem.datasetIndex].openStatus === "open")
            return tooltipItem.index === 0 && "Opened at : " + formatted;
          return (
            (tooltipItem.index === 0 ? "Opened at" : "Closed at") +
            " : " +
            formatted
          );
        },
        footer: function (tooltipItem, data) {
          let val = data.datasets[tooltipItem[0].datasetIndex].data;
          let diff = moment(val[1].x).diff(moment(val[0].x), "minutes");
          let out;
          if (diff > 0) out = "Open Duration : " + diff + " minutes";
          else {
            diff = moment(val[1].x).diff(moment(val[0].x), "seconds");
            out = "Open Duration : " + diff + " seconds";
          }
          return out;
        },
      },
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          type: "time",
          position: "bottom",
          time: {
            displayFormats: {
              minute: "MMM D h:mm a",
              hour: "MMM D h:mm a",
              day: "MMM D YYYY h:mm a",
            },
          },
          ticks: {
            // beginAtzero: true,
            // stepSize: 1
            min: chartTime.start,
            max: chartTime.end,
          },
        },
      ],
      yAxes: [
        {
          type: "category",
          labels: props.labels,
          scaleLabel: {
            // display: false
          },
          ticks: {
            // stepSize:1
            // beginAtZero: true,
            // max: 10
          },
        },
      ],
    },
  };

  return (
    <ChartLayout
      location_id={props.location_id}
      unit_id={props.unit_id}
      location_name={props.location_name}
      unit_name={props.unit_name}
      metric_name={props.metric_name}
      metrics={props.title}
      dataInterval={props.interval}
      setDataInterval={setDataInterval}
      handleDataInterval={handleDataInterval}
      timeRangeOption={timeRangeOption}
      handleTimeRangeOption={handleTimeRangeOption}
      availableIntervals={availableIntervals}
      allowedIntervals={allowedIntervals}
      startDate={props.start_time}
      endDate={props.end_time}
      handleStartDateChange={handleStartDateChange}
      handleEndDateChange={handleEndDateChange}
      is_warehouse_level_unit={props.is_warehouse_level_unit}
    >
      {props.errorChart ? (
        <div
          style={{
            boxSizing: "border-box",
            height: "100%",
            padding: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.errorChartMessage}
        </div>
      ) : props.loading ? (
        <div
          style={{
            boxSizing: "border-box",
            height: "100%",
            padding: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="black" />
        </div>
      ) : (
        <>
          <Box display="flex" my={1} alignItems="center">
            {props.accessDownloadFiles && (
              <div>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  className={classes.btn}
                  disabled={props.accessReadOnly}
                  onClick={() => {
                    !props.accessReadOnly &&
                      props.loadMetricReport(
                        props.match.params.sid,
                        props.start_time,
                        props.end_time,
                        props.interval,
                        "pdf"
                      );
                  }}
                >
                  Download PDF
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  className={classes.btn}
                  disabled={props.accessReadOnly}
                  onClick={() => {
                    !props.accessReadOnly &&
                      props.loadMetricReport(
                        props.match.params.sid,
                        props.start_time,
                        props.end_time,
                        props.interval,
                        "xlsx"
                      );
                  }}
                >
                  Download XLSX
                </Button>
              </div>
            )}
            <Box display="flex" flex={1} />
            {/* <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={zoomOption}
                    onChange={() => {
                      setZoomOption(!zoomOption);
                    }}
                  />
                }
                label="Zoom"
              />
            </FormGroup> */}
          </Box>
          <Scatter
            ref={chartRef}
            height={60}
            data={{ dataset: [] }}
            options={options}
          />
        </>
      )}
    </ChartLayout>
  );
}

const useStyles = makeStyles((theme) => ({
  btn: {
    marginLeft: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => {
  // chart data processing
  let labels = ["", state.metricChart.metric_name, ""];
  let data = [];
  state.metricChart.data.forEach((item, key) => {
    let oTime = new Date(item.vesda_active_time);
    oTime.getTimezoneOffset();
    let openTime = oTime.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    let cTime = item.vesda_inactive_time
      ? new Date(item.vesda_inactive_time)
      : state.metricChart.end_time.toDate();
    cTime.getTimezoneOffset();
    let closeTime = cTime.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    data.push({
      within_threshold: item.within_threshold,
      open_status: item.vesda_inactive_time === null ? "open" : "closed", // status = open if vesda_inactive_time is null
      point: [
        {
          x: openTime,
          y: state.metricChart.metric_name,
        },
        {
          x: closeTime,
          y: state.metricChart.metric_name,
        },
      ],
    });
  });

  return {
    start_time: state.metricChart.start_time,
    end_time: state.metricChart.end_time,
    interval: state.metricChart.interval,
    is_warehouse_level_unit: state.metricChart.is_warehouse_level_unit,
    location_id: state.metricChart.location_id,
    unit_id: state.metricChart.unit_id,
    location_name: state.metricChart.location_name,
    unit_name: state.metricChart.unit_name,
    metric_name: state.metricChart.metric_name,
    labels: labels,
    chartData: data,
    threshold: state.metricChart.threshold,
    control_limits: state.metricChart.control_limits,
    loading: state.metricChart.loading,
    updated: state.metricChart.updated,
    errorChart: state.metricChart.error,
    errorChartMessage: state.metricChart.errorMessage,
    errorReport: state.metricReport.error,
    errorReportMessage: state.metricReport.errorMessage,
    // access
    accessDownloadFiles: state.roleAccess.downloadFiles,
    accessReadOnly: state.roleAccess.readOnly,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadMetricChart: (id, start_time, end_time, interval) => {
      dispatch(loadMetricChart(id, start_time, end_time, interval));
    },
    loadMetricReport: (id, start_time, end_time, interval, file_type) => {
      dispatch(loadMetricReport(id, start_time, end_time, interval, file_type));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Analytics)
);
