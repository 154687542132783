import axios from "axios";

import {
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  ADD_USER_RESET,
} from "./types";

export const addUser = ({
  userName,
  firstName,
  lastName,
  password,
  receiveAlerts,
  isActive,
  roleId,
}) => {
  return (dispatch) => {
    dispatch(loading());
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/users`,
        {
          User_Name: userName,
          First_Name: firstName,
          Last_Name: lastName,
          password: password,
          is_receive_alerts: receiveAlerts,
          is_active: isActive,
          role_id: roleId,
        },
        { headers }
      )
      .then(({ data }) => {
        const payload = {
          data: data,
        };
        dispatch(success(payload));
      })
      .catch((err) => {
        console.log("ERROR! [addUserAction] " + err.message);
        const payload = {
          errorMessage: err.message,
        };
        dispatch(error(payload));
      });
  };
};

const loading = () => ({
  type: ADD_USER,
});

const success = (data) => ({
  type: ADD_USER_SUCCESS,
  payload: {
    ...data,
  },
});

const error = (data) => ({
  type: ADD_USER_ERROR,
  payload: {
    ...data,
  },
});

export const reset = () => {
  return (dispatch) => {
    dispatch({
      type: ADD_USER_RESET,
    });
  };
};
